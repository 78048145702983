@font-face {
	font-family: 'Graphik';
	src: url('../public/assets/fonts/Graphik-Medium.woff2') format('woff2'),
	url('../public/assets/fonts/Graphik-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('../public/assets/fonts/Graphik-ThinItalic.woff2') format('woff2'),
	url('../public/assets/fonts/Graphik-ThinItalic.woff') format('woff');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Graphik';
	src: url('../public/assets/fonts/Graphik-ExtralightItalic.woff2') format('woff2'),
	url('../public/assets/fonts/Graphik-ExtralightItalic.woff') format('woff');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Graphik';
	src: url('../public/assets/fonts/Graphik-Regular.woff2') format('woff2'),
	url('../public/assets/fonts/Graphik-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('../public/assets/fonts/Graphik-Extralight.woff2') format('woff2'),
	url('../public/assets/fonts/Graphik-Extralight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('../public/assets/fonts/Graphik-SemiboldItalic.woff2') format('woff2'),
	url('../public/assets/fonts/Graphik-SemiboldItalic.woff') format('woff');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Graphik';
	src: url('../public/assets/fonts/Graphik-Semibold.woff2') format('woff2'),
	url('../public/assets/fonts/Graphik-Semibold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('../public/assets/fonts/Graphik-MediumItalic.woff2') format('woff2'),
	url('../public/assets/fonts/Graphik-MediumItalic.woff') format('woff');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Graphik';
	src: url('../public/assets/fonts/Graphik-RegularItalic.woff2') format('woff2'),
	url('../public/assets/fonts/Graphik-RegularItalic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Graphik';
	src: url('../public/assets/fonts/Graphik-Thin.woff2') format('woff2'),
	url('../public/assets/fonts/Graphik-Thin.woff') format('woff');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('../public/assets/fonts/Graphik-Light.woff2') format('woff2'),
	url('../public/assets/fonts/Graphik-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Graphik';
	src: url('../public/assets/fonts/Graphik-Bold.woff2') format('woff2'),
	url('../public/assets/fonts/Graphik-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}



* {
	box-sizing: border-box;
	font-family: 'Graphik', serif;
}

h1,h2,h3,h4,h5,h6 {
	font-weight: normal;
}

html, body {
	min-height: 100vh;
}
